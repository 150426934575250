<template>
  <div>
    <!-- 代金券面值 -->
    <div class="dyb clearfix">
      <div class="dybleft">
        <!-- 左侧手机样式 -->
        <div style="padding-top: 50px;">
          <couponPhoneModel :couponBody="couponBody"></couponPhoneModel>
        </div>
      </div>
      <!-- 卡券详情 -->
      <div class="righta">
        <el-form :model="couponBody" label-width="120px" ref="couponBodyForm" :rules="rules" class="dybright">
          <div v-if="cardMsg.card_type == 2 ? true : false">
            <p>基本信息</p>
            <div class="coupon_name">
              <el-form-item label="优惠券名称" :rules="[
            { required: true, message: '优惠券名称为必填项', trigger: 'blur' }
          ]" prop="coupon_name">
                <el-input v-model.trim="couponBody.coupon_name" class="allwith" placeholder="请输入10个字符以内的名称"
                  maxlength="10"></el-input>
              </el-form-item>
            </div>
            <!-- 时间 -->
            <div class="block1 valid_timelong">
              <el-form-item label="有效期" prop="valid_timelong">
                <!-- <span style="width:93px;text-align:end;display:inline-block;">有效期：</span> -->
                <div style="display: inline-block;">
                  <el-input style="width: 100px;" v-model.number="couponBody.valid_timelong"></el-input>
                </div>
                <span style="padding-left: 12px;">{{ valid_timelong_unit_filter(couponBody.valid_timelong_unit)
                  }}</span>
              </el-form-item>
            </div>
          </div>
          <div v-if="cardMsg.card_type == 2">
            <!-- <p>优惠券内容</p> -->
            <div style="margin-bottom:14px;">
              <el-form-item label="优惠券类型" prop="valid_timelong">
                <el-radio :disabled="category == 'new' ? false : true" v-model="couponBody.coupon_type" :label="1">折扣券
                </el-radio>
                <el-radio :disabled="category == 'new' ? false : true" v-model="couponBody.coupon_type" :label="2">满减券
                </el-radio>
              </el-form-item>
            </div>
            <div class="coupon_value" v-if="couponBody.coupon_type == 1">
              <el-form-item label="折扣" prop="coupon_value" ref="coupon_value">
                <!-- <span>折扣：</span> -->
                <div style="display: inline-block;">
                  <el-input style="width: 100px;" v-model="couponBody.coupon_value"></el-input>
                </div>
                <span style="padding-left: 12px;">折</span>
              </el-form-item>
              <div style="color: #ccc; padding-left: 50px; font-size: 12px;">提示：请输入0.1~9.9之间的数字，精确到小数点后1位</div>
            </div>
            <div class="coupon_threshold_price" v-else>
              <el-form-item prop="coupon_threshold_price" label="满减金额" ref="coupon_threshold_price">
                <span style="padding-right: 6px;">满</span>
                <el-input style="width: 60px;" :controls="false" v-model="couponBody.coupon_threshold_price"
                  :disabled="category == 'new' ? false : true"></el-input>
                <span style="margin: 0 6px;">元，减</span>
                <el-input style="width: 60px;" :controls="false" v-model="couponBody.coupon_value"
                  :disabled="category == 'new' ? false : true"></el-input>
                <span style="margin: 0 6px;">元</span>
              </el-form-item>
            </div>
          </div>
          <div>
            <el-form-item label="适用范围">
              <el-radio v-model="couponBody.goods_limit" :label="0">全场通用</el-radio>
              <el-radio v-model="couponBody.goods_limit" :label="1">部分商品使用 </el-radio>
            </el-form-item>
            <div style="padding-left:30px" v-if="couponBody.goods_limit == 1">
              <span>已选择商品，共{{ pageData.length }}件</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <el-button type="text" @click="handleChangeShop">去选择商品
              </el-button>
            </div>
            <div>
              <el-button v-if="$route.query.id" type="primary" size="small" style="margin-top: 22px;"
                @click="handleModifyCoupon">修改
              </el-button>
              <el-button v-else type="primary" style="margin-top: 22px;" size="small" @click="next1">保存
              </el-button>
            </div>
          </div>
        </el-form>
        <!-- 部分商品列表 -->
        <div style="margin-left: 90px;" v-if="couponBody.goods_limit == 1 && (pageData && pageData.length)">
          <couponTable :pageData="pageData" @clickRemove="clickRemove"></couponTable>
        </div>
      </div>
      <en-goods-picker-fx :show="dialogGoodsShow" :pickupGift="1" :showChooseBtn="false" :default-data="pageData"
        @close="dialogGoodsShow = false" @chooseAll="allChoose" :fdList="fdList" />
    </div>
  </div>
</template>
<script>
import * as API_coupon from "@/api/coupon";
import * as API_MallManageMent from "@/api/mallManagement";
import * as API_Floor from "@/api/floor";
import { Foundation } from "@/../ui-utils";
import { $oss } from "@/services/oss/oss"
import couponTable from './components/coupon-table.vue' // 部分商品表格
import couponPhoneModel from './components/coupon-phone-model.vue'; // 左侧手机
export default {
  components: {
    couponTable,
    couponPhoneModel
  },
  data () {
    const timelongValidatePass = (rule, value, callback) => {
      const reg = /^(?:[1-9]\d{0,3}|1\d{3}|2000)$/;
      if (!reg.test(value) || value == 0) return callback(new Error('只允许输入1~2000的正整数'))
      callback()
    }
    const valueValidatePass = (rule, value, callback) => {
      // 优惠类型 1:折扣 2:满减
      if (this.couponBody.coupon_type == 1) {
        const reg = /^[0-9]{1}(\.[0-9])?$/
        console.log(this.couponBody);
        if (!reg.test(value)) return callback(new Error('请输入0.1~9.9之间的数字，最多到小数点后一位'))
        callback()
      } else {
        callback()
      }
    }
    const priceValidatePass = (rule, value, callback) => {
      const reg = /^([1-9]\d*|0)(\.\d{1,2})?$/
      if (!reg.test(value) || !reg.test(this.couponBody.coupon_value)) return callback(new Error('只允许输入0和小数点后两位的正数'))
      if (+value <= +this.couponBody.coupon_value) return callback(new Error('减免金额不能大于门槛金额'))
      callback()
    }
    return {
      pageData: [], // 选择的商品信息
      name: "",
      textarea: "",
      time: [],
      radio: 1,
      couponBody: {
        coupon_name: '',// 优惠券名称
        coupon_type: 1,//优惠类型 1:折扣 2:满减
        valid_timelong: 1,// 有效期 int
        valid_timelong_unit: 3, // 有效时长单位， 1月 2年 3天
        create_time: null, // 创建时间
        coupon_threshold_price: '',// int 优惠门槛价格
        coupon_value: '', // double 优惠金额/折扣
        goods_limit: 0,
        goods_ids: [],
        goods_list: []
      },
      rules: {
        valid_timelong: [
          { required: true, message: '请输入有效期', trigger: 'blur' },
          { validator: timelongValidatePass, trigger: 'blur' }
        ],
        coupon_value: [
          { required: true, message: '请输入折扣', trigger: 'blur' },
          { validator: valueValidatePass, trigger: 'blur' }
        ],
        coupon_threshold_price: [
          { validator: priceValidatePass, trigger: 'blur' }
        ]
      },
      cardMsg: {
        coupon_type: 1, //优惠券类型
        goods_limit: 0, //优惠券适用范围   0 全部商品   1 部分商品
        card_name: "", //优惠券标题
        start_time: 0, //优惠券开始时间
        end_time: 0, //优惠券标题结束时间
        card_type: 2, //   1：礼品卡 2：优惠券 3：代金券 4：充值卡
        coupon_threshold_price: 0, //优惠门槛价格
        card_value: 0, //优惠券面值
        limit_num: 0, //每人限领数量
        remark: "", //优惠券备注
        url: "", //优惠券跳转链接  自定义链接
        logo_url: "",
        state: 1,
        flag: 2,
        // 挑选的id
        goods_ids: [],
      },
      fileList: [
        // {name: "",url: ""}
      ],
      id: "",
      dialogGoodsShow: false,
      params: {
        //会员价格大值
        high_price: "",
        //会员价格小值
        low_price: "",
        //市场价格大值
        high_mktprice: "",
        //市场价格小值
        low_mktprice: "",
        //商品来源，0普通商品，1京东商品，2苏宁商品
        goods_source: "1",

        self_goods: "2",
        //利润率大值
        profit_max: "",
        //利润率小值
        profit_min: "",
        //商品名称
        keyword: "",
        //页码
        page_no: 1,
        //分页数
        page_size: 20,
        //排序
        sort: "",
        category_path: "",
      },
      chooseChangeList: [],
      tableData: {},
      // 列表总数据
      data_total: 0,
      goods_sum: 0,
      discountMsg: "",

      shop_list: [],
      fdList: [],
      category: "new",
      flag: false
    };
  },
  props: {
    yfather: Object,
  },
  watch: {
    $route: function () {
      if (this.$route.name === "couponEdit") {
        this.cardMsg.card_type = 2;
        this.name = "优惠券";
        console.log(this.cardMsg.card_type, "cardMsg.card_type");
      } else if (this.$route.name === "cashCouponEdit") {
        this.cardMsg.card_type = 3;
        this.name = "代金券";
        console.log(this.cardMsg.card_type, "cardMsg.card_type");
      } else {
        this.name = "充值卡";
        this.cardMsg.card_type = 4;
      }
    },
    radio: function () {
      if (this.radio === 1) this.cardMsg.url = "";
    },
    "cardMsg.coupon_type": {
      handler () {
        if (!this.$route.query.id) {
          this.cardMsg.coupon_threshold_price = "";
          this.cardMsg.card_value = "";
        }
      },
      deep: true
    },
    'couponBody.coupon_type': {
      handler () {
        console.log(1324);
        if (this.flag) return this.flag = false
        this.couponBody.coupon_value = ''
        this.couponBody.coupon_threshold_price = ''
        this.$refs?.coupon_threshold_price?.resetField()
        this.$refs?.coupon_value?.resetField()
      }
    }
  },

  created () {
    if (this.$route.query.id) {
      // this.cardDetail();
      this.getCouponDetail(this.$route.query.id);
      // this.category = "edit";
      this.flag = true
    }
  },
  methods: {
    valid_timelong_unit_filter (value) {
      if (value == 1) {
        return '个月'
      } else if (value == 2) {
        return '年'
      } else {
        return '天'
      }
    },
    // 一个高精度乘法计算的函数
    accMul (arg1, arg2) {
      let m = 0
      let s1 = arg1.toString()
      let s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) { }
      try {
        m += s2.split('.')[1].length
      } catch (e) { }
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      )
    },
    getNavs () {
      API_Floor.getNav().then((response) => {
        response.forEach((res) => {
          res.self_goods = Foundation.getAllKey(res.url).self_goods;
        });
        this.fdList = response;
      });
    },
    handleSelectShopName () {
      // shop_list
      this.cardMsg.shop_name = this.shop_list.find(
        (o) => o.shop_id === this.cardMsg.shop_id
      ).shop_name;
    },
    djjOk () {
      if (!this.cardMsg.card_name) {
        this.$message.error("请输入卡券名称！");
      } else if (isNaN(parseInt(this.cardMsg.card_value))) {
        this.$message.error("卡券面值必须是数字！");
      } else if (this.cardMsg.card_value === "0" || !this.cardMsg.card_value) {
        this.$message.error("请输入卡券面值！");
      } else if (parseFloat(this.cardMsg.card_value) <= 0) {
        this.$message.error("卡券面值必须大于0！");
      } else if (!this.cardMsg.start_time || !this.cardMsg.end_time) {
        this.$message.error("请选择有效期！");
      } else {
        API_coupon.cardAdd(this.cardMsg)
          .then(() => {
            this.$router.push({ path: "/tools/promotion-manage/cash-coupon-list" });
          })
          .catch(() => { });
      }
    },
    // 商品选择器抛出的方法
    allChoose (list) {
      this.pageData = [...list]
    },
    // 移除商品
    clickRemove (row) {
      this.pageData.splice(row.$index, 1)
    },
    // 修改优惠券
    async handleModifyCoupon () {
      this.$refs.couponBodyForm.validate(async valid => {
        if (!valid) return this.$message.error('存在必填项未填写')
        const obj = {
          coupon_name: '',// 优惠券名称
          coupon_type: 1,//优惠类型 1:折扣 2:满减
          valid_timelong: 1,// 有效期 int
          valid_timelong_unit: 3, // 有效时长单位， 1月 2年 3天
          create_time: null, // 创建时间
          coupon_threshold_price: '',// int 优惠门槛价格
          coupon_value: '', // double 优惠金额/折扣
          goods_limit: 0,
          id: null,
          goods_ids: [],
          goods_list: []
        }
        Object.keys(obj).forEach(key => obj[key] = this.couponBody[key])
        if (this.couponBody.goods_limit) { // 如果是部分商品 将ids传到后台
          let ids = [];
          this.pageData.forEach((item) => {
            ids.push(item.goods_id);
          });
          obj.goods_ids = ids;
          obj.goods_list = this.pageData
        }
        //折扣券要返回后台金额/10
        if (obj.coupon_type == 1) obj.coupon_value = (+obj.coupon_value / 10).toFixed(2)
        await API_coupon.putCoupons(obj)
        this.$router.push('/tools/promotion-manage/coupon-list')
      })
    },
    // 去选择商品按钮
    handleChangeShop () {
      this.getNavs();
      this.dialogGoodsShow = true;
    },
    async getCouponDetail (id) {
      const res = await API_coupon.getSingleCoupons(id)
      if (res.data.goods_limit) {
        const goods = await API_coupon.getGoods({ goods_ids: res.data.goods_ids })
        this.pageData = goods.data || []
      }
      //后台返回折扣券金额*10
      if (res.data.coupon_type === 1) this.flag = false, res.data.coupon_value = this.accMul(res.data.coupon_value, 10) /*(res.data.coupon_value * 10).toFixed(1)*/
      if (!res.data.coupon_threshold_price) res.data.coupon_threshold_price = ''
      this.couponBody = res.data
    },
    handleModifyCard () {
      let obj = {};
      /*
        coupon_type: 1, //优惠券类型
        goods_limit: 0, //优惠券适用范围
        card_name: "", //优惠券标题
        start_time: 0, //优惠券开始时间
        end_time: 0, //优惠券标题结束时间
        card_type: 2, //   1：礼品卡 2：优惠券 3：代金券 4：充值卡
        coupon_threshold_price: 0, //优惠门槛价格
        card_value:"",//优惠券面值
        limit_num: 0, //每人限领数量
        remark:"",//优惠券备注
        url: ""//优惠券跳转链接  自定义链接
        logo_url: ""
      */
      obj.card_name = this.cardMsg.card_name;
      obj.card_type = this.cardMsg.card_type;
      obj.coupon_type = this.cardMsg.coupon_type;
      obj.goods_limit = this.cardMsg.goods_limit;
      obj.start_time = this.cardMsg.start_time;
      obj.end_time = this.cardMsg.end_time;
      obj.coupon_threshold_price = this.cardMsg.coupon_threshold_price;
      obj.card_value = this.cardMsg.card_value;
      obj.remark = this.cardMsg.remark;
      obj.url = this.cardMsg.url;
      obj.state = this.cardMsg.state;
      obj.goods_ids = this.cardMsg.goods_ids;
      obj.logo_url = this.cardMsg.logo_url;
      if (this.cardMsg.card_type === 4) {
        obj.shop_id = this.cardMsg.shop_id;
      }
      if (!this.cardMsg.card_name) {
        this.$message.error("请输入卡券名称！");
        return;
      } else if (!this.cardMsg.start_time || !this.cardMsg.end_time) {
        this.$message.error("请选择有效期！");
        return;
      } else if (!this.cardMsg.shop_id) {
        this.$message.error("请选择所属商城！");
        return;
      } else {
        API_coupon.editCardmsg(this.$route.query.id, obj).then((res) => {
          this.$message.success("修改成功");
          this.$router.go(-1);
        });
      }
    },
    next1 () {
      this.$refs.couponBodyForm.validate(async valid => {
        if (!valid) return this.$message.error('存在必填项未填写')
        // this.$emit("fson", this.cardMsg);
        if (this.couponBody.goods_limit) { // 如果是部分商品 将ids传到后台
          let ids = [];
          this.pageData.forEach((item) => {
            ids.push(item.goods_id);
          });
          this.couponBody.goods_ids = ids;
          this.couponBody.goods_list = this.pageData
        }

        const tempCouponBody = JSON.parse(JSON.stringify(this.couponBody))
        //折扣券要返回后台金额*10
        if (tempCouponBody.coupon_type == 1) tempCouponBody.coupon_value = (+tempCouponBody.coupon_value / 10).toFixed(2)
        // 部分商品可用将商品传过去
        await API_coupon.postCoupons(tempCouponBody)
        this.$router.push('/tools/promotion-manage/coupon-list')
      })
    },

  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-form-item {
//   margin-bottom: 0;
// }
.coupon_name {
  ::v-deep .el-form-item__error {
    // padding-left: 92px;
  }
}

.valid_timelong {
  ::v-deep .el-form-item__error {
    // padding-left: 64px;
  }
}

.coupon_value {
  ::v-deep .el-form-item__error {
    // padding-left: 50px;
  }
}

.coupon_threshold_price {
  ::v-deep .el-form-item__error {
    // padding-left: 98px;
  }
}

.add:before {
  content: "*";
  color: #a91a1a;
  margin-right: 4px;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.dyb /deep/ .el-dialog__body {
  padding: 20px 20px;
}

.conditions {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 20px;
}

.el-pagination {
  padding: 10px 0px;
  text-align: right;
}

.allwith {
  width: 240px;
}

.kjbz {
  /* margin-top: 20px; */
  display: inline-block;
}

.block1 .block {
  padding: 20px 0px;
  display: inline-block;
  width: 100px;
}

.zhi {
  margin-left: 20px;
}

.block1 .block .el-date-editor {
  width: 240px;
}

.block1 .block .el-date-editor .el-input__inner {
  text-align: center;
  padding-left: 15px;
  padding-right: 0px;
}

.dyb {
  box-sizing: border-box;
  /* margin-top: 50px; */
  // padding-left: 200px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.card {
  position: relative;
  top: 100px;
  overflow: hidden;
  width: 350px;
  height: 200px;
  background: rgba(71, 162, 236, 1);
  border-radius: 10px;
}

.card .title {
  margin: 0px;
  width: 36px;
  height: 12px;
  margin-left: 19px;
  margin-top: 25px;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}

.card .money {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* height: 31px; */
  font-size: 30px;
  font-family: DINCond-Black;
  font-weight: 900;
  color: rgba(255, 255, 255, 1);
  margin-left: 12px;
  margin-top: 16px;
}

.card .time {
  position: absolute;
  bottom: 10px;
  margin-top: 38px;
  margin-left: 12px;
  height: 9px;
  font-size: 9px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.righta {
  float: left;
}

.dybright {
  padding: 1px 40px;
  margin-left: 50px;
  /* border: 1px solid #ccc; */
  width: 560px;
  // margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 11px;
}

.logo {
  margin: 20px 0px;
  display: flex;
}

.logo .upload-demo {
  display: inline-block;
  width: 240px;
}

.text {
  display: inline-block;
  line-height: 54px;
}
</style>
